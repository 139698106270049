body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #bbb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  height: 100%;
  background-color: white;
  box-sizing: border-box;
  border: 1px solid #777;
  align-items: center;
  vertical-align: middle;
  text-transform: uppercase;
  font-size: 1.5em;
  -webkit-box-shadow: 9px 4px 26px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 9px 4px 26px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 9px 4px 26px 0px rgba(34, 60, 80, 0.2);

  border-radius: 20%;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  user-select: none;
}

.card.word {
  font-size: 1.2em;
}

.card img {
  width: 100%;
}

.card.selected {
  background-color: #f0f0f0;
  border-width: 3px;
  border-color: brown;
  translate: 0 -0.1rem;
  transform: rotateZ(2deg) scale(1.04);
}

.card.shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.card.hidden {
  opacity: 0;
}

@keyframes shake {
  0%  { transform: translate(2px, 1px)   rotate(0deg);  }
  10% { transform: translate(-1px, -2px) rotate(-2deg); }
  20% { transform: translate(-3px, 0px)  rotate(3deg);  }
  30% { transform: translate(0px, 2px)   rotate(0deg);  }
  40% { transform: translate(1px, -1px)  rotate(1deg);  }
  50% { transform: translate(-1px, 2px)  rotate(-1deg); }
  60% { transform: translate(-3px, 1px)  rotate(0deg);  }
  70% { transform: translate(2px, 1px)   rotate(-2deg); }
  80% { transform: translate(-1px, -1px) rotate(4deg);  }
  90% { transform: translate(2px, 2px)   rotate(0deg);  }
  100%{ transform: translate(1px, -2px)  rotate(-1deg); }
}

.react-draggable {
  cursor: move;
}

.react-draggable-dragging {
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  opacity: 0.8;
  z-index: 9000;
}

.react-draggable.card {
  position: absolute;
}

.glyph {
    font-weight: bold;
    font-size: 4rem;
    color: #333;
    /*font-style: italic;*/
    font-family: monospace;
}
